@use "../../global/" as g;

#Container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;

  // @include g.mq(md) {
  //   display: grid;
  //   grid-template-columns: 247px 1fr;
  // }
}

#MainBox {
  width: 100%;
  margin-top: 70px;

  @include g.mq(md) {
    margin-top: 0;
    width: calc(100% - 200px);
  }
}
