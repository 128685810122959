@use "../../global/" as g;

.l-footer {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: g.$gray05;
  color: g.$white;

  @include g.mq(md) {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  &__inner {
    max-width: 1236px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;

    @include g.mq(md) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &__sp {
    @include g.mq(md) {
      display: none;
    }
  }

  &__sp-sns {
    background-color: g.$white;
  }

  &__sp-donation {
    margin-top: 16px;
  }

  &__top {
    margin-top: 30px;
    @include g.mq(md) {
      margin-top: 0;
    }
  }

  &__top-menu {
    display: flex;
    flex-wrap: wrap;
    column-gap: 94px;
    // column-gap: 92px;
    row-gap: 30px;

    @include g.mq(xl) {
      column-gap: normal;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__top-menu-head {
    font-weight: 700;
    font-size: g.rem(18);
    @include g.lh(20, 29);

    @include g.mq(md) {
      font-size: g.rem(20);
    }
  }

  &__top-under-menu {
    margin-top: 16px;
  }

  &__top-under-item-link {
    position: relative;
    padding-left: 16px;
    text-decoration: underline;
    text-underline-offset: 3px;
    @include g.lh(16, 30);

    &.u-text-upper {
      text-transform: uppercase;
    }

    @include g.mq(md) {
      font-size: g.rem(16);
      padding-left: 20px;
    }

    &::before {
      content: "";
      background: url("../images/common/icon_arrow-right-solid-white.svg")
        no-repeat center;
      background-size: contain;
      display: inline-block;
      width: 10px;
      height: 9px;
      position: absolute;
      left: 0;
      top: calc(50% - 4.5px);
    }
  }

  &__bottom {
    margin-top: 40px;
    @include g.mq(md) {
      margin-top: 104px;
      max-width: 845px;
    }
  }

  &__bottom-menu {
    display: flex;
    flex-direction: column;

    @include g.mq(md) {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 72px;
      row-gap: 20px;
    }
  }

  &__bottom-item {
    font-weight: 700;
    font-size: g.rem(16);

    @include g.mq(md) {
      font-size: g.rem(18);
    }
  }

  &__copyright {
    letter-spacing: 0.05em;
    margin-top: 40px;
    font-size: g.rem(12);

    @include g.mq(md) {
      font-size: g.rem(16);
      margin-top: 93px;
    }
  }
}
