@charset "UTF-8";
/*--------------------------------------------------------------
* フォントの読み込み
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* 使用font の変数
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* フォントサイズ の変数
----------------------------------------------------------------*/
/* pc
_______________________________________________________________________________________________________*/
/* sp
_______________________________________________________________________________________________________*/
/* =========================================================================================================
	共通定義する値
========================================================================================================= */
/* コンテンツ幅
_______________________________________________________________________________________________________*/
/* 使用色
_______________________________________________________________________________________________________*/
/* イージング
_______________________________________________________________________________________________________*/
/*
 * Easing function
 * Original：http://easings.net/
 */
/*--------------------------------------------------------------
* globalフォルダの_index.scss
----------------------------------------------------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-size: 0.875rem;
  font-display: swap;
  margin: 0 auto;
  padding: 0;
  background-color: #ffffff;
  color: inherit;
  color: #4d5058;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0;
  position: relative;
  overflow-x: hidden;
}
body.top {
  font-family: "Noto Sans JP", sans-serif;
}
body header,
body .l-footer {
  font-family: "Noto Sans JP", sans-serif;
}
body figure {
  margin-bottom: 0;
}
body.is-nav-open {
  overflow: hidden;
}
@media screen and (min-width: 0) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 576px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1025px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1200px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1400px) {
  body {
    position: relative;
  }
}

a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  opacity: 0.8;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

/*--------------------------------------------------------------
* foundationフォルダの_index.scss
----------------------------------------------------------------*/
#Container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

#MainBox {
  width: 100%;
  margin-top: 70px;
}
@media screen and (min-width: 768px) {
  #MainBox {
    margin-top: 0;
    width: calc(100% - 200px);
  }
}

.l-footer {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #9598a0;
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .l-footer {
    padding-top: 100px;
    padding-bottom: 120px;
  }
}
.l-footer__inner {
  max-width: 1236px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .l-footer__inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (min-width: 768px) {
  .l-footer__sp {
    display: none;
  }
}
.l-footer__sp-sns {
  background-color: #ffffff;
}
.l-footer__sp-donation {
  margin-top: 16px;
}
.l-footer__top {
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .l-footer__top {
    margin-top: 0;
  }
}
.l-footer__top-menu {
  display: flex;
  flex-wrap: wrap;
  column-gap: 94px;
  row-gap: 30px;
}
@media screen and (min-width: 1200px) {
  .l-footer__top-menu {
    column-gap: normal;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
.l-footer__top-menu-head {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.45;
}
@media screen and (min-width: 768px) {
  .l-footer__top-menu-head {
    font-size: 1.25rem;
  }
}
.l-footer__top-under-menu {
  margin-top: 16px;
}
.l-footer__top-under-item-link {
  position: relative;
  padding-left: 16px;
  text-decoration: underline;
  text-underline-offset: 3px;
  line-height: 1.875;
}
.l-footer__top-under-item-link.u-text-upper {
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .l-footer__top-under-item-link {
    font-size: 1rem;
    padding-left: 20px;
  }
}
.l-footer__top-under-item-link::before {
  content: "";
  background: url("../images/common/icon_arrow-right-solid-white.svg") no-repeat center;
  background-size: contain;
  display: inline-block;
  width: 10px;
  height: 9px;
  position: absolute;
  left: 0;
  top: calc(50% - 4.5px);
}
.l-footer__bottom {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .l-footer__bottom {
    margin-top: 104px;
    max-width: 845px;
  }
}
.l-footer__bottom-menu {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .l-footer__bottom-menu {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 72px;
    row-gap: 20px;
  }
}
.l-footer__bottom-item {
  font-weight: 700;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .l-footer__bottom-item {
    font-size: 1.125rem;
  }
}
.l-footer__copyright {
  letter-spacing: 0.05em;
  margin-top: 40px;
  font-size: 0.75rem;
}
@media screen and (min-width: 768px) {
  .l-footer__copyright {
    font-size: 1rem;
    margin-top: 93px;
  }
}

.c-page-top-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 200;
  visibility: hidden;
  opacity: 0;
  transform: translateY(200px);
  transition: 0.4s ease-in-out;
}
@media screen and (min-width: 768px) {
  .c-page-top-btn {
    right: 50px;
    bottom: 20px;
  }
}
.c-page-top-btn.is-show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.is-nav-open .c-page-top-btn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(200px);
}
.is-loadding .c-page-top-btn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(200px);
}
.c-page-top-btn__body {
  display: block;
  background-color: #9698a0;
  width: 50px;
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: relative;
  transition: 0.3s ease-in;
}
@media screen and (min-width: 768px) {
  .c-page-top-btn__body {
    width: 80px;
    height: 80px;
  }
}
.c-page-top-btn__body::before {
  content: "";
  background: url("../images/common/icon_arrow-top-line.svg") no-repeat center;
  background-size: contain;
  width: 16px;
  height: 15.5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) {
  .c-page-top-btn__body::before {
    width: 30px;
    height: 30px;
  }
}

/*--------------------------------------------------------------
* layoutフォルダの_index.scss
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* projectフォルダの_index.scss
----------------------------------------------------------------*/
/* =========================================================================================================
	* foundation   //基準の設定
========================================================================================================= */
/* =========================================================================================================
	* layout _l
========================================================================================================= */
/* =========================================================================================================
  * component _c
========================================================================================================= */