@use "../global" as g;

*,
*::before,
*::after {
  box-sizing: border-box;
}

// html {
//   font-size: 62.5%;
// }

body {
  // font-family: g.$NotoSans;
  font-size: g.rem(g.$sp-base);
  font-display: swap;
  margin: 0 auto;
  padding: 0;
  background-color: g.$white;
  color: inherit;
  color: g.$black-main;
  font-weight: 500;
  @include g.lh(16, 32);
  letter-spacing: 0;
  position: relative;
  overflow-x: hidden;

  &.top {
    font-family: g.$NotoSans;
  }

  header,
  .l-footer {
    font-family: g.$NotoSans;
  }

  figure {
    margin-bottom: 0;
  }

  &.is-nav-open {
    overflow: hidden;
  }

  @include g.mq(xs) {
    position: relative;
  }

  @include g.mq(sm) {
    position: relative;
  }

  @include g.mq(md) {
    position: relative;
  }

  @include g.mq(lg) {
    position: relative;
  }

  @include g.mq(xl) {
    position: relative;
  }

  @include g.mq(xxl) {
    position: relative;
  }
}

a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  opacity: 0.8;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}
